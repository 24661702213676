import React, { useEffect, useState, useRef } from "react";
import SearchBar from "../components/SearchBar.tsx";
import CourseListItem from "../components/CourseListItem.tsx";
import Pagination from "../components/Pagination.tsx";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar.tsx";
import CourseListLoader from "../components/CourseListLoader.tsx";
import Footer from "../components/Footer.tsx";
import config from "../config.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTh, faList, faPercentage, faPlusMinus } from "@fortawesome/free-solid-svg-icons";

import "../App.css";

interface Course {
  id: number;
  SUBJECT_COURSE_SECTION: string;
  COURSE_TITLE: string;
  PRIMARY_INSTRUCTOR_NAME: string;
  SECONDARY_INSTRUCTOR_NAME: string;
  A_PLUS: string;
  A: string;
  A_MINUS: string;
  B_PLUS: string;
  B: string;
  B_MINUS: string;
  C_PLUS: string;
  C: string;
  C_MINUS: string;
  D_PLUS: string;
  D: string;
  D_MINUS: string;
  F: string;
  WITHDRAWN: string;
  SEMESTER: string;
  YEAR: string;
  LACKS_PLUS_MINUS: number;
}

interface ApiDataInterface {
  data: Course[],
  totalItems: number
}

const CourseList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true); // Added loading state
  const [data, setData] = useState<Course[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currSearchQuery, setCurrSearchQuery] = useState<string>("");
  const [viewType, setViewType] = useState<string>("grid");
  const [showPercents, setShowPercents] = useState<boolean>(false);
  const [showPlusMinus, setShowPlusMinus] = useState<boolean>(true);

  const pageSize:number = 9;
  // @ts-ignore
  const SERVER:string = config[process.env.NODE_ENV]["SERVER"]; // grab the correct server url based on the environment

  const pageRef = useRef(null);

  useEffect(() => {
    const pageParam = new URLSearchParams(window.location.search).get("page");
    const queryParam = new URLSearchParams(window.location.search).get("q");
    const page:number = pageParam ? parseInt(pageParam) : 1;
    const query:string = queryParam ? queryParam.toLowerCase() : "";
    const savedViewType = localStorage.getItem('viewType');
    const savedShowPercents = localStorage.getItem('showPercents');
    const savedShowPlusMinus = localStorage.getItem('showPlusMinus');
    if (savedViewType) {
      setViewType(savedViewType as 'list' | 'grid');
    }
    if (savedShowPercents) {
      setShowPercents(savedShowPercents === 'true');
    }
    if (savedShowPlusMinus) {
      setShowPlusMinus(savedShowPlusMinus === 'true');
    }
    setCurrentPage(page);
    setCurrSearchQuery(query);

    getCourses(page, query);
    // @ts-ignore
    pageRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    const url:string = `/courses?page=${currentPage}${
      currSearchQuery ? "&q=" + currSearchQuery : ""
    }`;
    window.history.pushState({}, "", url);
  }, [currentPage, currSearchQuery]);

  useEffect(() => {
    document.title = "DAGrades | Courses";
  }, []);

  const getCourses = async (page:number, q:string) => {
    try {
      setCurrSearchQuery(q);
      const res = await fetch(`${SERVER}/courses?page=${page}&q=${q}`);

      if (!res.ok) {
        throw new Error("Invalid Request");
      }
      const { data, totalItems }: ApiDataInterface = await res.json();
      setData(data);
      setTotalPages(Math.ceil(totalItems / pageSize));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setCurrentPage(1);
      console.log(err.message);
    }
  };

  const handleChangePage = (page:string) => {
    if (page === "+1") {
      setCurrentPage((prevPage:number) => prevPage + 1);
      getCourses(currentPage + 1, currSearchQuery);
    } else if (page === "-1") {
      setCurrentPage((prevPage:number) => prevPage - 1);
      getCourses(currentPage - 1, currSearchQuery);
    } else if (page === "last") {
      setCurrentPage(totalPages);
      getCourses(totalPages, currSearchQuery);
    } else {
      setCurrentPage(parseInt(page));
      getCourses(parseInt(page), currSearchQuery);
    }
  }

  const navigate = useNavigate();

  const handleCourseClick = async (id:number) => {
    navigate(`/course?id=${id}`);
  };

  const toggleViewType = () => {
    const newViewType = viewType === 'list' ? 'grid' : 'list';
    setViewType(newViewType);
    localStorage.setItem('viewType', newViewType);
  };

  const toggleShowPercents = () => {
    const newShowPercents = !showPercents;
    setShowPercents(newShowPercents);
    localStorage.setItem('showPercents', String(newShowPercents));
  };

  const toggleShowPlusMinus = () => {
    const newShowPlusMinus = !showPlusMinus;
    setShowPlusMinus(newShowPlusMinus);
    localStorage.setItem('showPlusMinus', String(newShowPlusMinus));
  };

  return (
    <div ref={pageRef} className="w-full flex justify-start items-center flex-col relative min-h-screen bg-dark safari-course-list">
      <Navbar showHome/>
      <SearchBar
        handleSearch={getCourses}
        setCurrentPage={setCurrentPage}
        query={currSearchQuery}
      />
      <button onClick={toggleViewType} className="mb-2 p-2 text-white rounded">
        <FontAwesomeIcon icon={viewType === "grid" ? faList : faTh} />
      </button>
      {viewType === "list" && (
        <>
          <div className="flex space-x-2">
            <button onClick={toggleShowPercents} className="p-2 text-white rounded">
              <FontAwesomeIcon icon={faPercentage} style={{ color: showPercents ? 'green' : 'white' }} />
            </button>
            <button onClick={toggleShowPlusMinus} className="p-2 text-white rounded">
              <FontAwesomeIcon icon={faPlusMinus} style={{ color: showPlusMinus ? 'green' : 'white' }}/>
            </button>
          </div>
        </>
      )}
      {data.length === 0 && !loading ? (
        <div className="flex flex-col text-center items-center justify-start gap-5 text-zinc-300 mt-20">
        <FontAwesomeIcon icon={faBan} className="text-6xl text-red-500" />
        <h1 className={`text-4xl font-bold`}>Page Not Found</h1>
        <p className='text-xl max-w-80'>We could not find the page you were looking for. Please try searching again.</p>
      </div>
      ) : (
        loading ? (
        <CourseListLoader />
      ) : (
        viewType === "grid" ? (
          <div className="flex flex-col justify-start items-start p-10 mb-5 mt-10 w-full min-h-[32rem]">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-2 w-full h-full safari-course-grid">
              {data.map((course: Course, index: number) => (
                <div
                  key={index}
                  onClick={() => handleCourseClick(course[0])}
                  className="outline outline-1 text-zinc-700 flex h-full w-full justify-between items-center cursor-pointer rounded-md transition duration-300 p-4 bg-zinc-800 bg-opacity-70 hover:bg-opacity-100 safari-course-item"
                >
                  <CourseListItem course={course} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-start items-start p-10 mb-5 mt-0 w-full min-h-[32rem]">
            <div className="overflow-x-auto w-full">
            <table className="table-auto w-full text-zinc-300">
  <thead>
    <tr>
      <th className="px-4 py-2 text-left">Course Section</th>
      <th className="px-4 py-2 text-left">Course Title</th>
      <th className="px-4 py-2 text-left">Instructor</th>
      <th className="px-4 py-2 text-left">Term</th>
      {showPlusMinus ? (
        <>
          <th className="px-4 py-2 text-left">A+</th>
          <th className="px-4 py-2 text-left">A</th>
          <th className="px-4 py-2 text-left">A-</th>
          <th className="px-4 py-2 text-left">B+</th>
          <th className="px-4 py-2 text-left">B</th>
          <th className="px-4 py-2 text-left">B-</th>
          <th className="px-4 py-2 text-left">C+</th>
          <th className="px-4 py-2 text-left">C</th>
          <th className="px-4 py-2 text-left">C-</th>
          <th className="px-4 py-2 text-left">D+</th>
          <th className="px-4 py-2 text-left">D</th>
          <th className="px-4 py-2 text-left">D-</th>
        </>
      ) : (
        <>
          <th className="px-4 py-2 text-left">A</th>
          <th className="px-4 py-2 text-left">B</th>
          <th className="px-4 py-2 text-left">C</th>
          <th className="px-4 py-2 text-left">D</th>
        </>
      )}
      <th className="px-4 py-2 text-left">F</th>
      <th className="px-4 py-2 text-left">W</th>
      <th className="px-4 py-2 text-left">Class Size</th>
    </tr>
  </thead>
  <tbody>
    {data.map((course: Course, index: number) => {
      // Calculate the sum of the specified elements in the course array
      let courseSum = 0;
      for (let i = 5; i < 19; i++) {
        courseSum += parseInt(course[i]) || 0;
      }

      // Calculate sums for each grade group when showPlusMinus is false
      const gradeSums = {
        A: (parseInt(course[5]) || 0) + (parseInt(course[6]) || 0) + (parseInt(course[7]) || 0),
        B: (parseInt(course[8]) || 0) + (parseInt(course[9]) || 0) + (parseInt(course[10]) || 0),
        C: (parseInt(course[11]) || 0) + (parseInt(course[12]) || 0) + (parseInt(course[13]) || 0),
        D: (parseInt(course[14]) || 0) + (parseInt(course[15]) || 0) + (parseInt(course[16]) || 0),
        F: parseInt(course[17]) || 0,
        W: parseInt(course[18]) || 0,
      };

      const renderCells = (values: number[]) => {
        return values.map((value, idx) => (
          <td key={idx} className="px-4 py-2">{value}</td>
        ));
      };

      const renderPercentCells = (values: number[]) => {
        return values.map((value, idx) => (
          <td key={idx} className="px-4 py-2">{Math.round(100.0 * value / courseSum)}%</td>
        ));
      };

      if (showPercents && showPlusMinus) {
        return (
          <tr key={index} onClick={() => handleCourseClick(course[0])} className="cursor-pointer hover:bg-zinc-700">
            <td className="px-4 py-2">{course[1]}</td>
            <td className="px-4 py-2">{course[2]}</td>
            <td className="px-4 py-2">{course[3]}</td>
            <td className="px-4 py-2">{course[19]} {course[20]}</td>
            {renderPercentCells(Array.isArray(course) ? course.slice(5, 19) : [])}
            <td className="px-4 py-2">{courseSum}</td>
          </tr>
        );
      } else if (showPercents && !showPlusMinus) {
        return (
          <tr key={index} onClick={() => handleCourseClick(course[0])} className="cursor-pointer hover:bg-zinc-700">
            <td className="px-4 py-2">{course[1]}</td>
            <td className="px-4 py-2">{course[2]}</td>
            <td className="px-4 py-2">{course[3]}</td>
            <td className="px-4 py-2">{course[19]} {course[20]}</td>
            {renderPercentCells([gradeSums.A, gradeSums.B, gradeSums.C, gradeSums.D, gradeSums.F, gradeSums.W])}
            <td className="px-4 py-2">{courseSum}</td>
          </tr>
        );
      } else if (!showPercents && showPlusMinus) {
        return (
          <tr key={index} onClick={() => handleCourseClick(course[0])} className="cursor-pointer hover:bg-zinc-700">
            <td className="px-4 py-2">{course[1]}</td>
            <td className="px-4 py-2">{course[2]}</td>
            <td className="px-4 py-2">{course[3]}</td>
            <td className="px-4 py-2">{course[19]} {course[20]}</td>
            {renderCells(Array.isArray(course) ? course.slice(5, 19) : [])}
            <td className="px-4 py-2">{courseSum}</td>
          </tr>
        );
      } else {
        return (
          <tr key={index} onClick={() => handleCourseClick(course[0])} className="cursor-pointer hover:bg-zinc-700">
            <td className="px-4 py-2">{course[1]}</td>
            <td className="px-4 py-2">{course[2]}</td>
            <td className="px-4 py-2">{course[3]}</td>
            <td className="px-4 py-2">{course[19]} {course[20]}</td>
            {renderCells([gradeSums.A, gradeSums.B, gradeSums.C, gradeSums.D, gradeSums.F, gradeSums.W])}
            <td className="px-4 py-2">{courseSum}</td>
          </tr>
        );
      }
    })}
  </tbody>
</table>
            </div>
          </div>
        )
      ))}
      { totalPages > 1 && <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
        />}
      {!loading ? <Footer /> : null}
    </div>
  );
};

export default CourseList;