import React, { useEffect, useRef } from 'react'
import Navbar from '../components/Navbar.tsx'
import Footer from '../components/Footer.tsx'

const UsageGuide: React.FC = () => {

    const pageRef = useRef(null)

    useEffect(() => {
        //@ts-ignore
        pageRef.current.scrollIntoView()
        document.title = `DAGrades | Usage Guide` 
    }, [])

    return (
        <div ref={pageRef} className="w-full flex justify-center items-center flex-col relative min-h-screen bg-dark">
        <Navbar />
            <div className='mt-8 pb-8 flex flex-col items-start justify-start gap-32 min-h-screen w-3/4 md:text-justify text-zinc-300 leading-relaxed'>
                <div className='flex flex-col items-start justify-start gap-5'>
                <h1 className='md:text-5xl text-3xl text-primary font-bold underline-offset-[10px]'>Disclaimer</h1>
                <p className='text-lg md:text-2xl font-extralight'>DAGrades is an objective data-based tool for students to visualize past term courses’ grade distributions at De Anza College. Grade distributions are not necessarily an indicator of course difficulty nor a reflection on the instructor or department rewarding those grades. There are several factors that determine the ultimate grade distribution of a course, difficulty being only one.</p>
                </div>
            </div>
        <Footer />
        </div>
    );
}

export default UsageGuide