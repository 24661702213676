import React, { useEffect, useRef } from "react";
import Footer from "../components/Footer.tsx";
import Navbar from "../components/Navbar.tsx";

const Contact: React.FC = () => {
  const pageRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    pageRef.current.scrollIntoView();
    document.title = "DAGrades | Contact";
  }, []);

  return (
    <div ref={pageRef} className="w-full flex justify-center items-center flex-col relative min-h-screen bg-dark">
      <Navbar />
      <div className="flex flex-col justify-start items-center min-h-screen gap-10 w-full">
        <div className="flex justify-center items flex-col w-full text-zinc-300 text-center gap-5 mt-10 px-4">
          <h1 className="text-4xl md:text-7xl font-bold text-primary">Questions or Concerns?</h1>
          <p className="text-xl md:text-4xl">Reach out to <a href="mailto:support@deanzagrades.com" className="text-yellow-400">support@deanzagrades.com</a></p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;