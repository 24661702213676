import Navbar from "../components/Navbar.tsx"
import Footer from "../components/Footer.tsx"
import { Link } from 'react-router-dom'
import React, { useEffect, useState, useRef } from 'react'

const About: React.FC = () => {

  const pageRef = useRef(null)

  useEffect(() => {
    //@ts-ignore
    pageRef.current.scrollIntoView()
    document.title = "DAGrades | About"
  },[])

  return (
    <div ref={pageRef} className="w-full flex justify-center items-center flex-col relative min-h-screen text-zinc-700 bg-dark">
      <Navbar />
      <div
        className={`my-5 gap-10 flex flex-col text-zinc-300 w-3/4`}
      >
        <div className="w-full flex justify-start items-center flex-col gap-5 flex-grow">
          <h1 className="font-bold text-4xl lg:text-6xl text-left w-full">
            About{" "}
            <span className="text-primary hover:text-red-600 transition duration-300 text-left">
              DA
            </span>
            Grades
          </h1>
          <p className="text-lg text-left w-full">
            DAGrades is a website that allows other{" "}
            <a
              href="https://deanza.edu/"
              target="_blank"
              className="text-yellow-400 hover:text-yellow-500 transition duration-200"
            >
              De Anza College
            </a>{" "}
            students to view grades from previous quarters. <br />
            This website is not affiliated with De Anza College. <br />
            To begin using the website, simply click on the "Browse Courses"
            button on the{" "}
            <Link
              to="/"
              className="text-yellow-400 hover:text-yellow-500 transition duration-200"
            >
              home
            </Link>{" "}
            page and search for a course. <br />
            This data was obtained through Public Records Act Requests. Thanks to the{" "}
            <a
              href="https://discord.gg/dmzXD7YVvK"
              target="_blank"
              className="text-yellow-400 hover:text-yellow-500 transition duration-200">
                Data Science Club
              </a>{" "}
              and{" "}
              <a
              href="https://www.linkedin.com/in/jacobljohnston/"
              target="_blank"
              className="text-yellow-400 hover:text-yellow-500 transition duration-200">
                Jacob Johnston
              </a>{" "}
              for the data.<br />
            This site was designed by{" "}
            <a
              href="https://uigrades.vercel.app/"
              target="_blank"
              className="text-yellow-400 hover:text-yellow-500 transition duration-200"
            >
              UIGrades
            </a>. <br />
            NOTE: Grade data does NOT include some unusual grades such as EW/I/P/NP. Grade data from 2015-2019 TRUNCATES +/- grades as well. <br />
            Also, for class modality, the distinction between asynchronous online and synchronous online is not always correct (but generally is). <br />
            If you have any questions or concerns, please contact us using the{" "}
            <Link
              to="/contact"
              className="text-yellow-400 hover:text-yellow-500 transition duration-200"
            >
              Contact
            </Link>{" "}
            page.
          </p>
          <div className="w-full flex justify-start items-center gap-4">
            <Link to='/usage-guide' className="flex justify-center items-center text-xs w-1/2 h-12 md:w-auto md:h-auto text-center md:text-base opacity-70 hover:opacity-100 text-zinc-300 rounded-md transition duration-300 p-4 py-3 outline outline-1 outline-zinc-300">Usage Guide</Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About